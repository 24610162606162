/* General Layout */
.chat-app {
  display: flex;
  flex-direction: column;
}
#parent {
}
#narrow {
  max-width: 25%;
  /* Just so it's visible */
}
#wide {
  flex: 1;
}

/*#message-parent {
  display: flex;
  flex-direction: column; 
  align-items: flex-start;
  padding: 1rem; 
  max-width: 80%; 
  margin-bottom: 1rem; 
}*/



#button {
  align-self: flex-start; /* Align button to start of container */
  padding: 0.5rem 1rem; /* Padding for button */
  border: none; /* Remove border */
  color: #ffffff; /* Button text color */
  background-color: #007bff; /* Blue button */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s; /* Smooth color change on hover */
}

#button:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.chat-box {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  align-items: stretch; /* Center vertically */
}

.right-content {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  align-items: center; /* Center vertically */
}

.chat-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 97.5%;
  padding-left: 1%;
  position: relative;
  overflow: hidden;
}
.chat-container {
  display: flex;
  justify-content: space-between;
}

.instructions {
  width: 30%; /* Set a width for the instruction box */
  padding: 20px;
  background-color: #f9f9f9;
  border-right: 1px solid #ddd;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.chat-box {
  width: 65%; /* Set width for the chat box */
  margin-left: 20px; /* Space between instruction box and chat box */
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.messages {
  flex: 1; /* Allow messages to grow and fill the space */
  overflow-y: auto; /* Scrollable messages */
  padding: 10px;
  border-bottom: 1px solid #ddd; /* Separator between messages and input */
}

.user-message {
  text-align: right;
  float: right;
}

.bot-message {
  text-align: left;
  margin: 5px 0;
}

input {
  padding: 10px;
  border: none;
  border-top: 1px solid #ddd;
  width: 100%; /* Full width */
  box-shadow: none; /* Remove default shadow */
}

input:focus {
  outline: none; /* Remove focus outline */
}

/* Chat Window Styling */
.chat-window {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
  border: solid;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* Message Styling */
/*.message {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  max-width: 75%;
  padding: 10px 15px;
  border-radius: 18px;
  font-size: 15px;
  line-height: 1.4;
  word-wrap: break-word;
}

.user-message {
  align-self: flex-end;
  background-color: #007bff; 
  color: #ffffff;
  float: right;
  position: relative;
  border-radius: 15px 15px 0px 15px;
}

.bot-message {
  align-self: flex-start;
  background-color: #e5e5ea; 
  color: #000000;
  border-radius: 15px 15px 15px 0px;
}*/

/* Scrollbar Styling */
.chat-window::-webkit-scrollbar {
  width: 8px;
}

.chat-window::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 4px;
}

/* Input Area Styling */
.input-area {
  display: flex;
  padding: 10px;
  position: "fixed";
  margin-bottom: 0;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid #ddd;
}

.input-area input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 15px;
}

.input-area button {
  margin-left: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.input-area button:hover {
  background-color: #0056b3;
}

/*Typing indicator */
@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.typing-indicator {
  display: flex;
  align-items: center;
}

.typing-indicator span {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: #000;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.typing-indicator span:nth-child(2) {
  animation-delay: 0.2s;
}

.typing-indicator span:nth-child(3) {
  animation-delay: 0.4s;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .message {
    max-width: 90%;
    font-size: 14px;
  }

  .input-area input {
    font-size: 14px;
  }

  .input-area button {
    font-size: 14px;
    padding: 8px 15px;
  }
}
