#upload-parent {
  display: flex;
  height: 800px; /* Keep this height for the entire section */
  padding-bottom: 5%;
}

#upload-narrow {
  max-width: 25%; /* Narrow section width */
}

#upload-wide {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column; /* Ensure children stack vertically */
}
.vertrag-window {
  flex: 1;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vertrag-app {
  display: flex;
  flex-direction: column;
}

.upload-container {
  text-align: center;
  display: flex;
  width: 97.5%;
  flex-direction: column; /* Stack items vertically */
  justify-content: center; /* Center items vertically */
  height: 100%; /* Use full height of the container */
  transition: opacity 0.3s ease; /* Smooth transition effect */
  border: solid;
  border-radius: 8px;
}

/* Styling for the upload button */
.upload-button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 10px;
  align-self: center;
}



/* Styling for submit button */
.submit-button {
  padding: 10px 20px;
  background-color: #008cba;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  align-self: center;
  border: none;
}

/* PDF viewer styling */
.pdf-viewer {
  width: 100%;
  height: 100%; /* Use full height of the parent container */
  flex: 1; /* Allow iframe to grow to fill available space */
  border: none; /* Remove border for a cleaner look */
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-top: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
